<template>
  <div class="container-fluid">
    <div class="calendar-app">
      <div class="row" style="margin-bottom: 12px;">
        <div class="col-6 mt-2" style="text-align: left;">
          <!-- Arrow -->
          <div class="arrow" style="display: inline-block; margin-right: 15px; cursor: pointer;">
            <div style="display: inline-block;" @click="onSelectArrow('left')">
              <i class="fas fa-chevron-left" style="font-size: large; margin-right: 5px;"></i>
            </div>
            <div style="display: inline-block;" @click="onSelectArrow('right')">
              <i class="fas fa-chevron-right" style="font-size: large; margin-left: 5px;"></i>
            </div>
          </div>
          <!-- Today -->
          <div class="today" style="display: inline-block; cursor: pointer;" @click="onSelectToday()">
            <span style="padding: 5px 10px 5px 10px; border: 1px solid green; border-radius: 10px;">Hôm nay</span>
          </div>
        </div>
        <div class="col-6" style="text-align: right;">
          <!-- Arrow -->
          <div style="display: inline-block; margin-right: 15px; font-weight: bold;">
            <span>Tuần {{ weekOfWeekNumber }}</span>
          </div>
          <!-- Today -->
          <div style="display: inline-block; font-weight: bold;">
            <span>Tháng {{ monthOfWeekNumber }}, {{ yearOfWeekNumber }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <div style="width: 100%; padding: 10px 0px 10px 0px; background-color: #e5e5e5; text-align: left;">
            <span style="margin-left: 20px;">{{ yearOfWeekNumber }}</span>
          </div>
          <div v-for="(event, index) in events" :key="event + index">
            <div class="row" style="padding: 15px 0px 15px 0px; border-bottom: 1px solid #e5e5e5;">
              <div class="col-1">
                <span style="font-size: large; font-weight: bold;">{{ event.date }}</span>
              </div>
              <div class="col-2" style="text-align: left;">
                <span style="font-size: large;">{{ event.day }}</span> 
              </div>
              <div class="col-9" style="text-align: left;">
                <div v-for="(event, index) in event.events" :key="event + index">
                  <span @click="selectEvent($event, event.id, event.name)" style="font-size: medium; cursor: pointer;">{{ event.name }}</span> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: absolute; top: 0; right: 0; background-color: #ffffff; height: 100%; z-index: 2;">
        <transition name="bounce">
          <div v-show="eventId != null" style="width: 350px;">
            <div style="float: right; cursor: pointer;" @click="eventId = null">
              <i class="fas fa-times" style="color: red; font-size: medium; margin-top: 8px; margin-right: 5px;"></i>
            </div>
            <attendance-list-dialog ref="attendanceListDialog" :isOutSideMeeting="true" @goMeeting="goMeeting"></attendance-list-dialog>
          </div>
        </transition>
      </div>
    </div>
    <confirm-modal
      ref="confirmModal"
      :content="confirmModalContent"
      @onConfirm="onConfirm()"
    ></confirm-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "Components/ConfirmModal";
import moment from "moment";
import AttendanceListDialog from "Components/AttendanceListDialog";
export default {
  components: {
    confirmModal: ConfirmModal,
    attendanceListDialog: AttendanceListDialog,
  },
  data: function() {
    return {
      confirmModalContent: "meeting.confirmChooseMeeting",
      eventId: null,
      eventTitle: null,
      weekNumber: 0,
      weekOfWeekNumber: 0,
      monthOfWeekNumber: null,
      yearOfWeekNumber: null,
      events: [],
      beforeYearOfWeekNumber: null
    };
  },
  computed: {
    ...mapGetters(["GET_EVENT_WITH_DATE_DATA"]),
  },
  watch: {
    GET_EVENT_WITH_DATE_DATA: function() {
      this.calendarEvents = [];
      let events = this.GET_EVENT_WITH_DATE_DATA.data;
      this.events = events
    },
  },
  methods: {
    /**
     * On confirm
     */
    onConfirm: function () {
      this.$store.state.app.inAMeeting = true
      this.$router.push({
        name: 'information.title'
      })
      sessionStorage.setItem('event_id', this.eventId);
    },
    /**
     * Select event
     */
    selectEvent: function (event, eventId, eventTitle) {
      event.preventDefault();
      this.eventId = eventId
      this.eventTitle = eventTitle
      this.$refs.attendanceListDialog.getAttendeeSpeakerList(eventId)
    },
    /**
     * Go to meeting
     */
    goMeeting: function () {
      this.$refs.confirmModal.onShowModalConfirm(this.eventTitle)
    },
    /**
     * Get week number
     */
    getWeekNumber (d) {
      // Copy date so don't modify original
      d = new Date(+d);
      d.setHours(0,0,0);
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setDate(d.getDate() + 4 - (d.getDay()||7));
      // Get first day of year
      var yearStart = new Date(d.getFullYear(),0,1);
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
      // Return array of year and week number
      return [d.getFullYear(), weekNo];
    },
    /**
     * On select today
     */
    onSelectToday: function () {
      this.weekNumber = moment(new Date(), "YYYY-MM-DD").week()
      this.onGetWeekDaysByWeekNumber(this.weekNumber)
    },
    /**
     * On select arrow
     */
    onSelectArrow: function (type) {
      if (type == 'left') {
        this.weekNumber -= 1
      } else {
        this.weekNumber += 1
      }
      this.onGetWeekDaysByWeekNumber(this.weekNumber)
    },
    /**
     * Get date by week number
     */
    getWeekDaysByWeekNumber(weeknumber) {
      var dateformat = "YYYY-MM-DD";
      var date = moment().isoWeek(weeknumber).startOf("week"),
        weeklength = 7,
        result = [];
      while (weeklength--) {
        let object = {
          day: date.format("dddd"),
          fullDate: date.format(dateformat),
          date: date.format('DD'),
          month: date.format('MM'),
          year: date.format('YYYY')
        };
        result.push(object);
        date.add(1, "day");
      }
      let filter = {
        params: {
          calendarType: 'week',
          dates: result
        }
      };
      this.GET_EVENT_WITH_DATE(filter);
    },
    /**
     * On get week days by week number
     */
    onGetWeekDaysByWeekNumber: function (weekNumber) {
      this.monthOfWeekNumber = moment().isoWeek(weekNumber).month() + 1
      this.yearOfWeekNumber = moment().isoWeek(weekNumber).year()
      this.weekOfWeekNumber = moment().isoWeek(weekNumber).week()
      this.getWeekDaysByWeekNumber(weekNumber);
    },
    ...mapActions(["GET_EVENT_WITH_DATE"]),
  },
  mounted() {
    this.weekNumber = moment(new Date()).weeks()
    this.onGetWeekDaysByWeekNumber(this.weekNumber)
  },
};
</script>
<style lang="scss" scoped>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "../../style/color.scss";
.calendar-app {
  position: relative;
  padding: 5px 0px 15px 0px;
  font-size: 14px;
}
.calendar-app-top {
  margin: 0 0 3em;
}
.calendar-app-calendar {
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
}
/deep/ .fc-button-primary {
  background-color: $primary !important;
  border: none;
}
/deep/ .fc-today-button {
  background-color: $light-primary !important;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
